export const useCustomMeta = (title: string, description?: string) => {
  const route = useRoute()
  const config = useRuntimeConfig()

  const defaultTitle = '헬로메이플'
  const metaTitle =
    title !== defaultTitle ? `${title} - ${defaultTitle}` : title
  const meta = [
    { property: 'og:title', content: metaTitle },
    {
      property: 'og:url',
      content: `${config.public.baseURL}${route.path}`
    },
    { name: 'twitter:title', content: metaTitle }
  ]
  if (description) {
    meta.push(
      {
        property: 'og:description',
        content: description
      },
      {
        name: 'twitter:description',
        content: description
      }
    )
  }
  useHead({
    title,
    meta
  })
}
