import FetchFactory from '../factory'

class TermModule extends FetchFactory {
  private TERMS = '/terms/v1'

  /**
   * 전면 배너 조회
   */
  getTerm(termId: number) {
    return this.call<TermResponse>('GET', `${this.TERMS}/${termId}`)
  }
}

export default TermModule
