<template>
  <img
    :src="getImageSrc(fileName)"
    :srcSet="srcSet"
    :width="width"
    :height="height"
    :alt="alt"
  />
</template>

<script setup>
const { fileName, alt, density, type } = defineProps({
  type: {
    type: String,
    default: 'image',
    validator(value, props) {
      // 값은 다음 문자열 중 하나와 일치해야 합니다.
      return ['image', 'icon'].includes(value)
    }
  },
  fileName: {
    type: String,
    required: true
  },
  alt: {
    type: String,
    default: 'image'
  },
  density: {
    type: Number,
    default: 1
  },
  width: {
    type: String
  },
  height: {
    type: String
  }
})
const { getImageSrc } = useImages()

const srcSet = computed(() => {
  let rv = ''
  if (density > 1) {
    rv = Array.from({ length: density - 1 })
      .reduce((acc, val, idx) => {
        const _d = 2 + idx

        return acc + `${getImageSrc(`${fileName}@${_d}x`)} ${_d}x,` // `${getImageSrc(`${fileName}@${_d}x}`)}, ${_d}x`
      }, rv)
      .slice(0, -1)
  }
  return rv
})
</script>
