import VueDOMPurifyHTML from 'vue-dompurify-html'
const TEMPORARY_ATTRIBUTE = 'data-temp-href-target'
export default defineNuxtPlugin((nuxtApp) => {
  nuxtApp.vueApp.use(VueDOMPurifyHTML, {
    // anchor tag 에 target 속성이 있을 경우에 대한 처리 (https://github.com/cure53/DOMPurify/issues/317#issuecomment-912474068)
    hooks: {
      beforeSanitizeAttributes: (node: any) => {
        if (node.tagName === 'A') {
          if (!node.hasAttribute('target')) {
            node.setAttribute('target', '_self')
          }

          if (node.hasAttribute('target')) {
            node.setAttribute(TEMPORARY_ATTRIBUTE, node.getAttribute('target'))
          }
        }
      },
      afterSanitizeAttributes: (node: any) => {
        if (node.tagName === 'A' && node.hasAttribute(TEMPORARY_ATTRIBUTE)) {
          node.setAttribute('target', node.getAttribute(TEMPORARY_ATTRIBUTE))
          node.removeAttribute(TEMPORARY_ATTRIBUTE)
          if (node.getAttribute('target') === '_blank') {
            node.setAttribute('rel', 'noopener')
          }
        }
      }
    }
  })
})
