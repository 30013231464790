<template>
  <i class="icon" :class="`icon_${iconName}`" :style="style"></i>
</template>

<script lang="ts" setup>
const { iconName, width, height } = defineProps({
  iconName: {
    type: String,
    required: true
  },
  width: {
    type: String,
    default: ''
  },
  height: {
    type: String,
    default: ''
  }
})

const style = computed(() => {
  const rv: { width: string | undefined; height: string | undefined } = {
    width: undefined,
    height: undefined
  }
  if (width) rv.width = width
  if (height) rv.height = height
  return rv
})
</script>

<style lang="scss" scoped>
.icon {
  display: inline-block;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  @include transition();
  &.icon_check {
    background-image: url('@/assets/icons/checkbox-enable.svg');
  }
  &.icon_check_active {
    background-image: url('@/assets/icons/checkbox-active.svg');
  }
  &.icon_check_disabled {
    background-image: url('@/assets/icons/checkbox-disable.svg');
  }
  &.icon_check_black {
    background-image: url('@/assets/icons/checkbox-black.svg');
  }
  &.icon_fail {
    width: 16px;
    height: 16px;
    background-image: url('@/assets/icons/info-fail.svg');
  }
  &.icon_success {
    width: 16px;
    height: 16px;
    background-image: url('@/assets/icons/info-success.svg');
  }
  &.icon_clear {
    width: 18px;
    height: 18px;
    background-image: url('@/assets/icons/input-clear.svg');
  }
  &.icon_chevron_right {
    width: 24px;
    height: 24px;
    background-image: url('@/assets/icons/chevron-right.svg');
  }
  &.icon_chevron_rounded {
    width: 24px;
    height: 24px;
    background-image: url('@/assets/icons/chevron-rounded.svg');
  }
  &.icon_chevron_right_rouned {
    width: 20px;
    height: 20px;
    background-image: url('@/assets/icons/chevron-right-rounded.svg');
  }
  &.icon_chevron_black {
    width: 20px;
    height: 20px;
    background-image: url('assets/icons/chevron-right-black.svg');
  }
  &.icon_notice {
    width: 20px;
    height: 20px;
    background-image: url('@/assets/icons/info-notice.svg');
  }
  &.icon_gnb_intro {
    width: 33px;
    height: 30px;
    background-image: url('@/assets/icons/gnb-intro.svg');
  }
  &.icon_gnb_speaker {
    width: 33px;
    height: 30px;
    background-image: url('@/assets/icons/gnb-speaker.svg');
  }
  &.icon_gnb_speech_bubble {
    width: 31px;
    height: 32px;
    background-image: url('@/assets/icons/gnb-speech-bubble.svg');
  }
  &.icon_gnb_burger {
    width: 32px;
    height: 32px;
    background-image: url('@/assets/icons/gnb-burger.svg');
  }
  &.icon_gnb_close {
    width: 32px;
    height: 32px;
    background-image: url('@/assets/icons/gnb-close.svg');
  }
  &.icon_windows {
    width: 28px;
    height: 28px;
    background-image: url('@/assets/icons/windows.svg');
  }
  &.icon_view_eye {
    width: 24px;
    height: 24px;
    background-image: url('@/assets/icons/view-eye.svg');
  }
  &.icon_arrow_right {
    width: 14px;
    height: 14px;
    background-image: url('@/assets/icons/arrow-right.svg');
  }
  &.icon_arrow_right_circle {
    width: 18px;
    height: 18px;
    background-image: url('@/assets/icons/arrow-right-circle.svg');
    &:hover {
      background-image: url('@/assets/icons/arrow-right-circle-active.svg');
    }
  }
  &.icon_check_circle {
    width: 24px;
    height: 24px;
    background-image: url('@/assets/icons/checkbox-black.svg');
  }
  &.icon_x_circle {
    width: 24px;
    height: 24px;
    background-image: url('@/assets/icons/x-circle.svg');
  }
  &.icon_repair {
    width: 19px;
    height: 19px;
    background-image: url('@/assets/icons/repair.svg');
  }
  &.icon_speaker {
    width: 19px;
    height: 19px;
    background-image: url('assets/icons/speaker.svg');
  }
  &.icon_warning {
    width: 19px;
    height: 19px;
    background-image: url('assets/icons/warning.svg');
  }
  &.icon_legal_close {
    width: 32px;
    height: 32px;
    background-image: url('assets/icons/legal-close.svg');
  }
  &.icon_legal_burger {
    width: 32px;
    height: 32px;
    background-image: url('assets/icons/legal-burger.svg');
  }
  &.icon_youtube {
    width: 27.32px;
    height: 20px;
    background-image: url('assets/icons/youtube.svg');
  }
}
</style>
