// locals
import FetchFactory from '../factory'

type AuthEnd = {
  authPGCode: string
  authTransactionToken: string
  authAccessCode: string
  memberType: string
  yearBirth: number | null
  authPGResult: {
    apiToken?: string
    resCd?: string
    encCertData2?: string
    certNo?: string
    dnHash?: string
    tid?: string
  }
}
export type { AuthEnd }

class AccountModule extends FetchFactory {
  private ACCOUNT = '/account/v1'

  /**
   * 아이디 중복 확인
   */
  checkDuplicateUserID(userId: string) {
    return this.call<any>('POST', `${this.ACCOUNT}/validate/userid`, {
      userid: userId
    })
  }
  /**
   * 이메일 중복 확인
   * retru
   */
  checkDuplicateEmail(email: string) {
    return this.call<any>('POST', `${this.ACCOUNT}/validate/email`, {
      email
    })
  }

  /**
   * 이메일 인증 코드 전송
   */
  sendCodeForEmail(email: string) {
    return this.call<any>(
      'POST',
      `${this.ACCOUNT}/verification/send/nomember/emailauth`,
      { email }
    )
  }
  /**
   * 이메일 인증 코드 검증
   */
  verifyCodeForEmail(data: { email: string; keyCode: string }) {
    return this.call<any>(
      'PATCH',
      `${this.ACCOUNT}/verification/verify/nomember/emailauth`,
      data
    )
  }
  authTransitionStart(targetUrl: string, authAccessCode: string) {
    return this.call<any>('POST', `${this.ACCOUNT}/auth/transaction/start`, {
      targetUrl,
      authAccessCode
    })
  }
  authTransitionEnd(data: AuthEnd) {
    return this.call<any>('POST', `${this.ACCOUNT}/auth/transaction/end`, {
      ...data
    })
  }
}

export default AccountModule
