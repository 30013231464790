export const appHead = {"meta":[{"charset":"utf-8"},{"name":"viewport","content":"width=device-width, initial-scale=1"},{"http-equiv":"cache-control","content":"max-age=0, no-cache, no-store, must-revalidate"},{"property":"og:image","content":"https://www.hellomaple.org/hellomaple.png"},{"property":"og:type","content":"website"},{"name":"twitter:card","content":"summary_large_image"},{"name":"naver-site-verification","content":"b312f4808c5ca93f3e4d7a3d9f6eeaf987248a61"}],"link":[{"rel":"icon","type":"image/x-icon","href":"/favicon.ico"},{"rel":"preload","as":"font","type":"font/woff2","crossorigin":"anonymous","href":"/_fonts/Maplestory-Bold.woff2"}],"style":[{"children":"@font-face{font-family:\"Maplestory\";font-weight:400;font-style:normal;font-display:optional;src:url('/_fonts/Maplestory-Bold.woff2') format('woff2');}.font_maple_story{font-family:\"Maplestory\";}"}],"script":[],"noscript":[]}

export const appBaseURL = "/"

export const appBuildAssetsDir = "/_nuxt/"

export const appCdnURL = ""

export const appLayoutTransition = false

export const appPageTransition = false

export const appViewTransition = false

export const appKeepalive = false

export const appRootId = "__nuxt"

export const appRootTag = "div"

export const appTeleportTag = "div"

export const appTeleportId = "teleports"

export const renderJsonPayloads = true

export const componentIslands = false

export const payloadExtraction = true

export const cookieStore = false

export const appManifest = true

export const remoteComponentIslands = false

export const selectiveClient = false

export const devPagesDir = null

export const devRootDir = null

export const devLogs = false

export const nuxtLinkDefaults = {"componentName":"NuxtLink"}

export const asyncDataDefaults = {"deep":true}

export const fetchDefaults = {}

export const vueAppRootContainer = '#__nuxt'

export const viewTransition = false