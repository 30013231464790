// locals
import FetchFactory from '../factory'

type SignUp = {
  userid: string
  password: string
  email?: string
  accountType: 'GENERAL' | 'TEACHER' | 'STUDENT' | 'NONE'
  yearBirth: number | null
  authTransactionToken?: string
  emailVerificationToken?: string
  terms: { termId: number; isAgree: boolean }[] | undefined
}

class SignUpModule extends FetchFactory {
  private SIGNUP = '/signup'
  /**
   * 약관 정보 조회
   * @param type
   * @returns
   */
  getTerms(type: 'NORMAL' | 'UNDER14' | undefined) {
    return this.call<any>('GET', `${this.SIGNUP}/v1/terms`, undefined, {
      params: { type }
    })
  }
  /**
   * 회원 가입
   * @param data
   * @returns
   */
  async signUp(data: SignUp) {
    return this.call<any>('POST', `${this.SIGNUP}/v1`, data)
  }
}

export default SignUpModule
