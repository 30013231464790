export default defineNuxtPlugin({
  dependsOn: ['a2s'],
  async setup(nuxtApp) {
    const eventType = process.client
      ? 'ontouchstart' in document.documentElement
        ? 'touchstart'
        : 'click'
      : ''
    const clickHandler = (e: Event) =>
      (nuxtApp.$sendLogFromElement as Function)(e.currentTarget, 'click')

    nuxtApp.vueApp.directive('log-a2s-click', {
      mounted: (element) => {
        element.addEventListener(eventType, clickHandler, true)
      },
      beforeUnmount: (element) => {
        element.removeEventListener(eventType, clickHandler, true)
      }
    })
    /** */
    nuxtApp.vueApp.directive('log-a2s-contents', {
      mounted: (element) =>
        (nuxtApp.$sendLogFromElement as Function)(element, 'contents')
    })
  }
})
