import FetchFactory from '../factory'

class BannerModule extends FetchFactory {
  private BANNER = '/banners/v1'

  /**
   * 전면 배너 조회
   */
  getInterstitialBanners() {
    return this.call('GET', `${this.BANNER}/interstitial`)
  }
  /**
   * 긴급 띠 배너 조회
   */
  getUrgentBanners() {
    return this.call<BannerResponse>(
      'GET',
      `${this.BANNER}/urgent`,
      undefined,
      {
        params: {
          displayTypes: 'PORTAL',
          type: 'URGENT',
          isIncludeFutureItem: false
        }
      }
    )
  }
}

export default BannerModule
